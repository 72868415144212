import { useEffect } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Tabs } from "antd";
import NewTasks from "./newTasks";
import MemberTasks from "./memberTasks";
import ComplaintTasks from "./complaintTasks";
import RenewalTasks from "./RenewalTasks";
import AuditTasks from "./AuditTasks";
import PermissionsGuard from "../../../components/Auth/can";
import AccessDenied from "../../../components/Auth/accessDenied";
import { useGlobalState } from "../../../globalStore";
// import "./index.css";
import { useFilterState } from "@/contexts/AdminFilterContext";
import BatchRegistrationList from "./Batch/BatchRegistrations";
import { useBatchFilters } from "@/hooks/UseBatchFilters";

function Tasks() {
  const { clearFilters } = useBatchFilters();
  const { state } = useGlobalState();
  const {
    filters: { tabKey },
    saveTabKey,
  } = useFilterState();

  useEffect(() => {
    if (tabKey !== "6") {
      clearFilters();
    }
  }, [tabKey]);

  const userId = Number.parseInt(localStorage.getItem("userId"));

  const tabsItems = [
    {
      label: "New Tasks",
      key: "1",
      children: <NewTasks />,
    },
    {
      label: (
        <span>
          My Tasks{" "}
          {(state.lookups?.assignedTaskCount || 0) > 0 && (
            <ExclamationCircleFilled
              style={{ color: "red", fontSize: "15px" }}
            />
          )}
        </span>
      ),
      key: "2",
      children: <MemberTasks memberId={userId} mode="my-tasks" />,
    },
    {
      label: "Complaint Tasks",
      key: "3",
      children: <ComplaintTasks memberId={userId} mode="my-tasks" />,
    },
    {
      label: "Renewal Tasks",
      key: "4",
      children: <RenewalTasks />,
    },
    {
      label: "Audit Tasks",
      key: "5",
      children: <AuditTasks />,
    },
    {
      label: "Batch Registrations",
      key: "6",
      children: <BatchRegistrationList />,
    },
  ];

  return (
    <PermissionsGuard
      perform={"tasks:view"}
      yes={() => (
        <>
          <div className="tasks-header-div">
            <h2>Tasks</h2>
          </div>
          <Tabs
            activeKey={tabKey}
            onChange={saveTabKey}
            tabPosition="top"
            items={tabsItems}
          />
        </>
      )}
      no={() => <AccessDenied permission="tasks:view" />}
    />
  );
}

export default Tasks;
