// GQL Mutations

import { gql } from "@apollo/client";
import { PROSECUTION_APPEARANCE_FRAGMENT } from "./queries";

export const LOGIN_MUTATION = gql`
  mutation login($token: String!) {
    login(token: $token) {
      id
      token
      firstName
      lastName
      email
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      firstName
      lastName
      email
      phone
      idNumber
    }
  }
`;

export const EMPLOYEE_INVITATION_MUTATION = gql`
  mutation createEmployeeInvitation($input: CreateEmployeeInvitationInput!) {
    createEmployeeInvitation(input: $input) {
      id
    }
  }
`;

export const CREATE_MANUAL_REG_INVITATION_MUTATION = gql`
  mutation createManualRegInvitation($input: CreateManualRegInvitationInput!) {
    createManualRegInvitation(input: $input) {
      invitation {
        id
      }
      user {
        id
      }
      alreadyExists
      isTransferable
    }
  }
`;

export const RESEND_EMPLOYEE_INVITATION_MUTATION = gql`
  mutation resendEmployeeInvitation($input: ReferenceEmployeeInvitationInput!) {
    resendEmployeeInvitation(input: $input) {
      result
    }
  }
`;

export const DELETE_EMPLOYEE_INVITATION_MUTATION = gql`
  mutation deleteEmployeeInvitation($input: ReferenceEmployeeInvitationInput!) {
    deleteEmployeeInvitation(input: $input) {
      result
    }
  }
`;

export const SEND_EMAIL_MUTATION = gql`
  mutation sendEmail($input: EmailDetails!) {
    sendEmail(input: $input)
  }
`;

export const CREATE_COMPANY = gql`
  mutation createCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
      registrationType
      registrationCapacity
      natureOfBusiness
      registeredName
      registeredNumber
      tradingName
      membershipNumber
      createdBy {
        id
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
      registrationType
      registrationCapacity
      natureOfBusiness
      registeredName
      registeredNumber
      tradingName
      membershipNumber
      createdBy {
        id
      }
    }
  }
`;

export const CREATE_COMPANY_REPRESENTATIVE = gql`
  mutation createCompanyRepresentative(
    $input: CreateCompanyRepresentativeInput!
  ) {
    createCompanyRepresentative(input: $input) {
      id
      firstName
    }
  }
`;

export const UPDATE_COMPANY_REPRESENTATIVE = gql`
  mutation updateCompanyRepresentative(
    $input: UpdateCompanyRepresentativeInput!
  ) {
    updateCompanyRepresentative(input: $input) {
      id
      firstName
    }
  }
`;

export const CREATE_PREQUALIFICATION = gql`
  mutation createPrequalification($input: CreatePrequalificationInput!) {
    createPrequalification(input: $input) {
      id
      onTrial
      convicted
      appliedForExemption
      curatedForLegalCapacity
      dateOfTrial
      typeOfOffence {
        id
        name
      }
      guiltyOfImproperConduct
    }
  }
`;

export const UPDATE_PREQUALIFICATION = gql`
  mutation updatePrequalification($input: UpdatePrequalificationInput!) {
    updatePrequalification(input: $input) {
      id
      onTrial
      convicted
      appliedForExemption
      curatedForLegalCapacity
      dateOfTrial
      typeOfOffence {
        id
        name
      }
      guiltyOfImproperConduct
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      email
      firstName
      lastName
      idNumber
      phone
      secondaryPhone
      secondaryEmail
      workPhone
      homePhone
      createdDate
      age
      maritalStatus
      ethnicity
      isEmancipated
      avatarUrl
      councilRegistrationNumber
      applications {
        registrationType
        registrationCapacity
        registeredAs
        applicationStatus {
          id
          name
        }
      }
      employment {
        id
        employerAddressType
        employerBranchLocation
        capacity {
          id
          name
        }
        businessUnit {
          id
          name
        }
        company {
          id
          financialYearStartDate
          financialYearEndDate
          registeredNumber
          registeredName
          membershipNumber
          vatNumber
          tradingName
        }
      }
      userUserRoles {
        userRole {
          name
          permissions {
            id
            name
          }
          stream {
            id
            name
          }
        }
      }
    }
  }
`;
export const CREATE_ADDRESS = gql`
  mutation createAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      id
      country
      city
      streetAddress
    }
  }
`;

export const VERIFY_USER_EMAIL_BY_TOKEN = gql`
  mutation verifyUserEmailByToken($token: String!) {
    verifyUserEmailByToken(token: $token)
  }
`;

export const UPDATE_EMAIL_RESEND_VERIFICATION = gql`
  mutation updateEmailResendVerificationToken($newEmail: String!) {
    updateEmailResendVerificationToken(newEmail: $newEmail)
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      id
      country
      city
      streetAddress
    }
  }
`;

export const CREATE_COMPANY_CONTACT = gql`
  mutation createCompanyContact($input: CreateCompanyContactInput!) {
    createCompanyContact(input: $input) {
      id
    }
  }
`;

export const UPDATE_COMPANY_CONTACT = gql`
  mutation updateCompanyContact($input: UpdateCompanyContactInput!) {
    updateCompanyContact(input: $input) {
      id
    }
  }
`;

export const CREATE_COMPANY_FINANCIAL_INFO = gql`
  mutation createCompanyFinancialInformation(
    $input: CreateFinancialInfoInput!
  ) {
    createCompanyFinancialInformation(input: $input) {
      company {
        id
      }
    }
  }
`;

export const UPDATE_COMPANY_FINANCIAL_INFO = gql`
  mutation updateCompanyFinancialInformation(
    $input: UpdateFinancialInfoInput!
  ) {
    updateCompanyFinancialInformation(input: $input) {
      company {
        id
      }
    }
  }
`;

export const CREATE_AUDITOR_ACCOUNTANT_INFO = gql`
  mutation createCompanyAccountant($input: CreateCompanyAccountantInput!) {
    createCompanyAccountant(input: $input) {
      businessName
      contactPersonName
      contactOfficeNumber
      contactCellNumber
      email
      role
    }
  }
`;

export const UPDATE_AUDITOR_ACCOUNTANT_INFO = gql`
  mutation updateCompanyAccountant($input: UpdateCompanyAccountantInput!) {
    updateCompanyAccountant(input: $input) {
      businessName
      contactPersonName
      contactOfficeNumber
      contactCellNumber
      email
      role
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation createDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      id
    }
  }
`;

export const ARCHIVE_DOCUMENT = gql`
  mutation updateDocument($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      id
    }
  }
`;

export const REQUEST_OTP = gql`
  mutation requestOTP($input: RequestOtpInput!) {
    requestOTP(input: $input)
  }
`;

export const VALIDATE_OTP = gql`
  mutation validateOTP($userId: Int!, $otp: Int!) {
    validateOTP(userId: $userId, otp: $otp)
  }
`;

export const DELETE_BANK_ACCOUNT = gql`
  mutation deleteCompanyBankAccount($id: Int!) {
    deleteCompanyBankAccount(id: $id)
  }
`;
export const VALIDATE_COMPANY_REGISTERATION_NUMBER = gql`
  mutation validateRegistrationNumber($registrationNumber: String!) {
    validateRegistrationNumber(registrationNumber: $registrationNumber) {
      registrationNumber
      registrationName
      data
    }
  }
`;

export const CREATE_USER_LANGUAGE_PROFICIENCY = gql`
  mutation createLanguageProficiency($input: CreateLanguageProficiencyInput!) {
    createLanguageProficiency(input: $input) {
      id
      proficiencyLevel {
        id
        name
      }
      abilities {
        id
        name
      }
      language {
        id
        name
      }
    }
  }
`;

export const UPDATE_USER_LANGUAGE_PROFICIENCY = gql`
  mutation updateLanguageProficiency($input: UpdateLanguageProficiencyInput!) {
    updateLanguageProficiency(input: $input) {
      id
      proficiencyLevel {
        id
        name
      }
      abilities {
        id
        name
      }
      language {
        id
        name
      }
    }
  }
`;
export const DELETE_USER_LANGUAGE_PROFICIENCY = gql`
  mutation deleteLanguageProficiency($id: Int!) {
    deleteLanguageProficiency(id: $id)
  }
`;

export const CREATE_USER_QUALIFICATION = gql`
  mutation createEducationalQualification(
    $input: CreateEducationalQualificationInput!
  ) {
    createEducationalQualification(input: $input) {
      id
      institutionName
      year
      qualification
      type {
        id
        name
      }
      user {
        highestGrade
        highestGradeYear
      }
    }
  }
`;

export const UPDATE_USER_QUALIFICATION = gql`
  mutation updateEducationalQualification(
    $input: UpdateEducationalQualificationInput!
  ) {
    updateEducationalQualification(input: $input) {
      id
      institutionName
      year
      qualification
      type {
        id
        name
      }
      user {
        highestGrade
        highestGradeYear
      }
    }
  }
`;

export const DELETE_USER_QUALIFICATION = gql`
  mutation deleteEducationalQualification($id: Int!) {
    deleteEducationalQualification(id: $id)
  }
`;

export const CREATE_INVOICE = gql`
  mutation createInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
      description
      amount
    }
  }
`;

export const CREATE_SAGE_INVOICE = gql`
  mutation createSageTaxInvoice($input: Invoices) {
    createSageTaxInvoice(input: $input) {
      ID
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation updateInvoice($input: UpdateInvoiceInput!) {
    updateInvoice(input: $input) {
      id
      description
      amount
    }
  }
`;

export const PROCESS_EMPLOYEE_PAYMENT = gql`
  mutation employeePayment(
    $userId: Int!
    $companyId: Int!
    $encrypted: String!
    $forcePaymentSuccess: Boolean
    $paymentTaskId: Int!
  ) {
    employeePayment(
      userId: $userId
      companyId: $companyId
      encrypted: $encrypted
      forcePaymentSuccess: $forcePaymentSuccess
      paymentTaskId: $paymentTaskId
    ) {
      redirectUrl
      transactionId
    }
  }
`;

export const PROCESS_COMPANY_PAYMENT = gql`
  mutation companyPayment(
    $companyId: Int!
    $encrypted: String!
    $forcePaymentSuccess: Boolean
  ) {
    companyPayment(
      companyId: $companyId
      encrypted: $encrypted
      forcePaymentSuccess: $forcePaymentSuccess
    ) {
      redirectUrl
      transactionId
    }
  }
`;

export const CREATE_TASK = gql`
  mutation createTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      id
    }
  }
`;

export const CREATE_AUDIT_TASK = gql`
  mutation createAuditTask($input: CreateAuditTaskInput!) {
    createAuditTask(input: $input) {
      id
    }
  }
`;

export const CREATE_TASKS = gql`
  mutation createTasks($input: CreateTasksInput!) {
    createTasks(input: $input) {
      success
    }
  }
`;

export const CREATE_COMPANY_REGISTRATION_TASK = gql`
  mutation createCompanyTask($input: CreateCompanyRegistrationTaskInput!) {
    createCompanyTask(input: $input) {
      success
    }
  }
`;

export const CREATE_EMPLOYEE_REGISTRATION_TASK = gql`
  mutation createBatchEmployeeTask($input: CreateBatchEmployeeTaskInput!) {
    createBatchEmployeeTask(input: $input) {
      id
      employee {
        firstName
        lastName
        idNumber
      }
    }
  }
`;

export const COMPLETE_BATCH_REGISTRATION = gql`
  mutation completeBatchRegistration($input: CompleteBatchRegistrationInput!) {
    completeBatchRegistration(input: $input) {
      id
      completed
      tasks
      financialTaskId
    }
  }
`;

export const CREATE_RELATED_TASK = gql`
  mutation createRelatedTask($parentTaskId: Int!, $relatedTaskId: Int!) {
    createRelatedTask(
      parentTaskId: $parentTaskId
      relatedTaskId: $relatedTaskId
    ) {
      id
      relatedTasks {
        id
        taskStatus
      }
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation updateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      id
      taskStatus
    }
  }
`;

export const UPSERT_USER_EMPLOYMENT = gql`
  mutation upsertUserEmployment($input: UpsertUserEmploymentInput!) {
    upsertUserEmployment(input: $input) {
      id
    }
  }
`;

export const CREATE_APPLICATION = gql`
  mutation createApplication($input: CreateApplicationInput!) {
    createApplication(input: $input) {
      id
    }
  }
`;

export const UPDATE_APPLICATION = gql`
  mutation updateApplication($input: UpdateApplicationInput!) {
    updateApplication(input: $input) {
      id
    }
  }
`;

export const CREATE_APPLICATION_REVIEW = gql`
  mutation createReview($input: CreateReviewInput!) {
    createReview(input: $input) {
      id
      hasPassed
      steps {
        id
        step
        hasPassed
        comments {
          id
          createdDate
          body
          author {
            id
            firstName
            lastName
            avatarUrl
          }
          documents {
            documentPath
          }
        }
      }
    }
  }
`;

export const CREATE_RENEWAL_REVIEW = gql`
  mutation createRenewalReview($input: CreateReviewInput!) {
    createRenewalReview(input: $input) {
      id
      hasPassed
    }
  }
`;

export const CREATE_AUDIT = gql`
  mutation createAudit($input: CreateAuditInput!) {
    createAudit(input: $input) {
      id
    }
  }
`;

export const CREATE_FINANCIAL_REVIEW = gql`
  mutation createFinancialReview($input: CreateReviewInput!) {
    createFinancialReview(input: $input) {
      id
      hasPassed
    }
  }
`;

export const CREATE_REVIEW_COMMENT = gql`
  mutation createReviewComment($input: CreateReviewCommentInput!) {
    createReviewComment(input: $input) {
      id
      hasPassed
    }
  }
`;

export const UPDATE_REVIEW_STEP = gql`
  mutation updateReviewStep($input: UpdateReviewStepInput!) {
    updateReviewStep(input: $input) {
      id
    }
  }
`;

export const UPDATE_APPLICATION_REVIEW = gql`
  mutation updateReview($input: UpdateReviewInput!) {
    updateReview(input: $input) {
      id
      hasPassed
      steps {
        id
        step
        hasPassed
        comments {
          id
          createdDate
          body
          author {
            id
            firstName
            lastName
            avatarUrl
          }
          documents {
            documentPath
          }
        }
      }
    }
  }
`;

export const CREATE_COMPLAINT = gql`
  mutation createComplaint($input: CreateComplaintInput!) {
    createComplaint(input: $input) {
      id
    }
  }
`;

export const CREATE_PROSECUTION_RECOMMENDATION = gql`
  mutation createProsecutionRecommendation(
    $input: CreateProsecutionRecommendationInput!
  ) {
    createProsecutionRecommendation(input: $input) {
      id
    }
  }
`;

export const CREATE_DEBT_COLLECTOR_RESPONSE = gql`
  mutation createDebtCollectorResponse(
    $input: CreateDebtCollectorResponseInput!
  ) {
    createDebtCollectorResponse(input: $input) {
      id
    }
  }
`;

export const CREATE_BATCH_REGISTRATION = gql`
  mutation createBatchRegistration($input: CreateBatchRegistrationInput!) {
    createBatchRegistration(input: $input) {
      id
    }
  }
`;

export const UPDATE_PROSECUTION_RECOMMENDATION = gql`
  mutation updateProsecutionRecommendation(
    $input: UpdateProsecutionRecommendationInput!
  ) {
    updateProsecutionRecommendation(input: $input) {
      id
    }
  }
`;

export const UPDATE_COMPLAINT = gql`
  mutation updateComplaint($input: UpdateComplaintInput!) {
    updateComplaint(input: $input) {
      id
      firstName
      lastName
      email
      contactNumber
      identificationType
      identificationNumber
      complaintAgainst
      debtCollectorName
      debtCollectorCompany
      debtCollectorContactNumber
      debtCollectorEmail
      description
      noticeEmailSent
      clientResponded
      prosecuted
      debtCollector {
        id
        idNumber
        firstName
        lastName
        userStatus
      }
      company {
        id
        registeredName
        tradingName
        contacts {
          cellphone
          email
          businessUnitContactName
          businessUnit {
            id
            name
          }
        }
      }
    }
  }
`;

export const CREATE_ACTIVITY = gql`
  mutation createActivity($input: CreateActivityInput!) {
    createActivity(input: $input) {
      id
      description
    }
  }
`;

export const SEND_COMPLAINT_NOTICE = gql`
  mutation sendComplaintNotice($input: SendComplaintNoticeInput!) {
    sendComplaintNotice(input: $input)
  }
`;

export const SEND_PROSECUTION_NOTICE = gql`
  mutation sendComplaintProsecutionNotice(
    $input: SendComplaintProsecutionNoticeInput!
  ) {
    sendComplaintProsecutionNotice(input: $input)
  }
`;

export const CREATE_COMPLAINT_PROSECUTION = gql`
  mutation createComplaintProsecution(
    $input: CreateComplaintProsecutionInput!
  ) {
    createComplaintProsecution(input: $input) {
      id
      reason
      noticeEmailSent
      chargeSheetGenerated
      noticeTemplateGenerated
      appearanceAddressGenerated
      numberOfCharges
      prosecutionCharges {
        id
        description
        alternativeCharge
        dateCreated
        dateModified
      }
      prosecutionAppearances {
        ...ProsecutionAppearanceFragment
      }
    }
  }
  ${PROSECUTION_APPEARANCE_FRAGMENT}
`;

export const UPDATE_COMPLAINT_PROSECUTION = gql`
  mutation updateComplaintProsecution(
    $input: UpdateComplaintProsecutionInput!
  ) {
    updateComplaintProsecution(input: $input) {
      id
      reason
      noticeEmailSent
      chargeSheetGenerated
      noticeTemplateGenerated
      appearanceAddressGenerated
      numberOfCharges
      prosecutionCharges {
        id
        description
        alternativeCharge
        dateCreated
        dateModified
      }
      prosecutionAppearances {
        ...ProsecutionAppearanceFragment
      }
    }
  }
  ${PROSECUTION_APPEARANCE_FRAGMENT}
`;

export const CREATE_PROSECUTION_APPEARANCE = gql`
  mutation createProsecutionAppearance(
    $input: CreateProsecutionAppearanceInput!
  ) {
    createProsecutionAppearance(input: $input) {
      ...ProsecutionAppearanceFragment
    }
  }
  ${PROSECUTION_APPEARANCE_FRAGMENT}
`;
export const UPDATE_PROSECUTION_APPEARANCE = gql`
  mutation updateProsecutionAppearance(
    $input: UpdateProsecutionAppearanceInput!
  ) {
    updateProsecutionAppearance(input: $input) {
      ...ProsecutionAppearanceFragment
    }
  }
  ${PROSECUTION_APPEARANCE_FRAGMENT}
`;

export const CREATE_TRANSACTION = gql`
  mutation createTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      id
      paymentMethod
    }
  }
`;

export const GENERATE_NOTICE_FILE = gql`
  mutation generateNoticeDocument(
    $complaintId: Int!
    $companyId: Int!
    $template: String!
  ) {
    generateNoticeDocument(
      complaintId: $complaintId
      companyId: $companyId
      template: $template
    )
  }
`;
export const CREATE_PENALTY = gql`
  mutation createPenalty($input: CreatePenaltyInput!) {
    createPenalty(input: $input) {
      id
      penaltyAmount
      paymentTerm
      penaltyStatus
    }
  }
`;

export const CREATE_PENALTY_INVOICE = gql`
  mutation createPenaltyInvoice($input: CreatePenaltyInvoiceInput!) {
    createPenaltyInvoice(input: $input)
  }
`;

export const CREATE_EFT_PAYMENT_KEY = gql`
  mutation createEFTPaymentKey($input: CreateEFTPaymentInput) {
    createEFTPaymentKey(input: $input) {
      key
      url
    }
  }
`;

export const PROCESS_EFT_PAYMENT = gql`
  mutation processEFTPayment($input: EFTPaymentTransactionInput) {
    processEFTPayment(input: $input) {
      transactionId
    }
  }
`;

export const PROCESS_DD_PAYMENT = gql`
  mutation processDDPayment($input: CreatePaymentTransaction) {
    processDDPayment(input: $input) {
      transaction {
        id
      }
      paymentTask {
        id
      }
    }
  }
`;

export const UPDATE_USER_EMAIL = gql`
  mutation updateEmail($input: UpdateEmailInput) {
    updateEmail(input: $input) {
      success
    }
  }
`;
