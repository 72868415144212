import { getPackageVersionNumber } from "./getPackageVersionNumber";
import { isLoggedIn } from "./isLoggedIn";
import { isTokenValid } from "./isTokenValid";
import { logger } from "./logger";
import { base64ToBlob } from "./base64ToBlob";
import { ADMIN_STREAMS } from "../constants";
import { addMonths, format, startOfMonth } from "date-fns";
import { isEmpty } from "lodash";
import { mkConfig, download, generateCsv } from "export-to-csv";
import { useUserState } from "@/stores/user";

export const isAdmin = (stream?: string) => {
  if (stream) {
    return ADMIN_STREAMS.includes(stream);
  }

  return ADMIN_STREAMS.includes(localStorage.getItem("userRoles"));
};

export const isSuperAdmin = (stream?: string) => {
  if (stream) {
    return stream === "Super User";
  }

  return localStorage.getItem("userRoles") === "Super User";
};

export const cleanup = (_isAdmin: boolean = false) => {
  const manualRegisterItems = [
    "registeringAs",
    "step4Saved",
    "step3Saved",
    "step2Saved",
    "step1Saved",
    "isExistingUser",
    "isTransferable",
    "manualRegistrationType",
    "step5Saved",
    "step6Saved",
    "step7Saved",
    "employerId",
    "step8Saved",
    "step9Saved",
    "manualRegisterId",
    "appliedForExemption",
    "registrationType",
    "manualRegistrationInvitationId",
    "manualRegisterCompanyId",
    "isMultipleEmployment",
    "registrationCapacity",
    "manualRegistrationApplicationId",
    "previousCompanyId",
    "regFees",
  ];

  if (!isAdmin) manualRegisterItems.push("companyId");

  manualRegisterItems.forEach((i) => localStorage.removeItem(i));
};

export const prioritize = (
  type: { id: number; name: string },
  priorityStatus: string,
  assignedTo?: { id: number }
): boolean => {
  const userId = useUserState.getState().id;
  const admin = isAdmin();
  const highPriority = !!priorityStatus;

  if (type?.name === "Registration" && !admin) {
    return true;
  }

  if (["Financial Ticket"].includes(type?.name) && highPriority && !admin) {
    return true;
  }

  if (
    ["Complaints", "Complaint", "Audit Certificate"].includes(type?.name) &&
    (assignedTo?.id === userId || highPriority)
  ) {
    return true;
  }

  return false;
};

export const generateMonths = (count: number = 6) => {
  const months: string[] = [];
  let date = startOfMonth(new Date());
  for (let i = 1; i <= count; i++) {
    date = addMonths(date, 1);
    months.push(format(date, "yyyy-MM-dd"));
  }

  return months;
};

export const generateArrayOfYears = () => {
  const max = new Date().getFullYear() + 1;
  const min = max - 9;
  const years: number[] = [];

  for (let i = max; i >= min; i--) {
    years.push(i);
  }

  return years;
};

export const isActiveMultiFilter = (multiFilter: Record<string, any>) => {
  return Object.entries(multiFilter).every(([_, value]) => !!value);
};

export const formatName = (record) => {
  const isEmployee =
    record.type?.name.includes("Employee") && !isEmpty(record.employee);
  const isComplaint =
    record.type?.name.includes("Complaint") && !isEmpty(record.complaint);
  const isFinance =
    record.type?.name.includes("Financial") && !isEmpty(record.employee);
  let name = "";

  if (isEmployee || isFinance) {
    name = `${record.employee?.firstName} ${record.employee?.lastName}`;
  } else if (isComplaint) {
    name = `${record.complaint?.firstName} ${record.complaint?.lastName}`;
  } else {
    name = `${record.company?.createdBy?.firstName} ${record.company?.createdBy?.lastName}`;
  }

  return name ?? "";
};

export const writeCSV = (title: string, fileName: string, data: any) => {
  const config = mkConfig({
    showTitle: true,
    title: title,
    useTextFile: false,
    useKeysAsHeaders: true,
    filename: fileName,
  });

  download(config)(generateCsv(config)(data));
};

export function formatFilters(filters: Record<string, any>) {
  let postFilters = {};
  for (let [key, value] of Object.entries(filters)) {
    if (value) {
      postFilters[key] = value;
    }
  }
  return postFilters;
}

export {
  base64ToBlob,
  logger,
  isTokenValid,
  isLoggedIn,
  getPackageVersionNumber,
};
