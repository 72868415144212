import { notifyError } from "@/utils/notification";
import { Column } from "@ant-design/charts";
import { useQuery } from "@apollo/client";
import {
  Card,
  Row,
  Col,
  Statistic,
  Space,
  Typography,
  Divider,
  Tooltip,
  Skeleton,
  Button,
  Tag,
} from "antd";
import { Fragment, useState } from "react";
import { GET_DASHBOARD_DATA } from "../Admin/graphql/queries";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useUserState } from "@/stores/user";
import { isSuperAdmin } from "@/utils";
import { ReloadOutlined } from "@ant-design/icons";

type UserSummary = {
  total: number;
  active: number;
  inactive?: number;
  suspended?: number;
  awaitingPayment?: number;
};

type EntityCount = {
  status?: string;
  month?: string;
  count: number;
};

function Dashboard() {
  const { firstName, lastName } = useUserState();
  const navigate = useNavigate();
  const [user, setUserSummary] = useState<UserSummary>(null);
  const [company, setCompanySummary] = useState<UserSummary>(null);
  const [task, setTaskSummary] = useState<UserSummary>(null);

  const [userCount, setUserCount] = useState<EntityCount[]>([]);
  const [companyCount, setCompanyCount] = useState<EntityCount[]>([]);
  const [taskCount, setTaskCount] = useState<EntityCount[]>([]);
  const [entitySummary, setEntitySummary] = useState<EntityCount[]>([]);
  const [jobs, setJobs] = useState<any>([]);

  const { activeUserRole, userRoles } = useUserState();
  const activeRole = userRoles.find((role) => role.id === activeUserRole);

  const { loading, refetch } = useQuery(GET_DASHBOARD_DATA, {
    fetchPolicy: "network-only",
    onCompleted: ({ getDashboardData: data }) => {
      setUserCount(data.userCount);
      setCompanyCount(data.companyCount);
      setTaskCount(data.taskCount);
      setJobs(data.jobs);

      setEntitySummary([
        ...data.application,
        ...data.withdrawnEmployment,
        ...data.renewal,
      ]);

      const userTotal = data.userCount.reduce(
        (acc, curr) => acc + curr.count,
        0
      );

      setUserSummary({
        total: userTotal,
        active:
          (data.userCount.find(
            (user) => user.status?.toLowerCase() === "active"
          )?.count || 0) +
          (data.userCount.find((user) => user.status?.toLowerCase() === "due")
            ?.count || 0),
        suspended:
          data.userCount.find(
            (user) => user.status.toLowerCase() === "suspended"
          )?.count || 0,
      });

      const companyTotal = data.companyCount.reduce(
        (acc, curr) => acc + curr.count,
        0
      );

      setCompanySummary({
        total: companyTotal,
        active:
          (data.companyCount.find(
            (company) => company.status?.toLowerCase() === "active"
          )?.count || 0) +
          (data.companyCount.find(
            (company) => company.status?.toLowerCase() === "due"
          )?.count || 0),
        suspended:
          data.companyCount.find(
            (company) => company.status?.toLowerCase() === "suspended"
          )?.count || 0,
      });

      const taskTotal = data.taskCount.reduce(
        (acc, curr) => acc + curr.count,
        0
      );

      setTaskSummary({
        total: taskTotal,
        active:
          (data.taskCount.find(
            (task) => task.status === "Awaiting Verification"
          )?.count || 0) +
          (data.taskCount.find((task) => task.status === "In Progress")
            ?.count || 0),
        awaitingPayment:
          data.taskCount.find((task) => task.status === "Awaiting Payment")
            ?.count || 0,
      });
    },
    onError: notifyError,
  });

  if (!isSuperAdmin(activeRole?.stream)) {
    return (
      <div className="d-flex m-5 h-100 justify-center">
        You do not have access to this page
      </div>
    );
  }

  if (loading)
    return (
      <div className="m-3">
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Card>
              <Skeleton loading active />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Skeleton loading active />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Skeleton loading active />
            </Card>
          </Col>
          <Card>
            <Skeleton loading active />
            <Skeleton loading active />
          </Card>
        </Row>
      </div>
    );

  return (
    <div className="m-3">
      <div className="flex-row justify-between">
        <div className="flex-column justify-between gap-0">
          <Typography.Title
            level={3}
            className="mb-0"
            style={{ color: "#707070" }}
          >
            CFDC Dashboard
          </Typography.Title>
          <Typography.Text>
            Hi,{" "}
            <b>
              {firstName} {lastName}
            </b>
          </Typography.Text>
        </div>
        <Button
          shape="circle"
          onClick={() => refetch()}
          icon={<ReloadOutlined />}
        />
      </div>

      <Row gutter={5} className="mt-3">
        <Col span={6}>
          <Card
            title="Today's Jobs"
            extra={
              <Button
                size="small"
                type="ghost"
                onClick={() => {
                  navigate("/admin/background-jobs");
                }}
              >
                View
              </Button>
            }
          >
            {jobs.map((job: any) => (
              <Fragment key={job.id}>
                <div className="flex-row justify-between">
                  <div className="flex-column">
                    <Typography.Text>{job.functionName}</Typography.Text>
                    <Typography.Text type="secondary">
                      {format(new Date(job.startedAt), "dd MMMM HH:mm")} -{" "}
                      {format(new Date(job.endedAt), "HH:mm")}
                    </Typography.Text>
                  </div>
                  <Tag
                    color={
                      job.status === "started"
                        ? "processing"
                        : job.status === "finished"
                        ? "success"
                        : "error"
                    }
                    className="p-2 mr-0"
                  >
                    {job.status.toUpperCase()}
                  </Tag>
                </div>
                <Divider />
              </Fragment>
            ))}
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="flex-column"
            title="User Count"
            extra={
              <Button
                type="ghost"
                onClick={() => navigate("/admin/members")}
                size="small"
              >
                View
              </Button>
            }
          >
            <Statistic
              value={user?.total || 0}
              valueStyle={{ textAlign: "center" }}
              className="mb-2"
            />
            <Space
              split={<Divider type="vertical" />}
              className="full-width justify-center"
            >
              <Tooltip title="Users with active or due status">
                <div className="flex-column justify-center text-center gap-0">
                  <Typography.Text className="bold">
                    {user?.active || 0}
                  </Typography.Text>
                  <Typography.Text>Active</Typography.Text>
                </div>
              </Tooltip>
              <Tooltip title="Users with suspended status">
                <div className="flex-column justify-center text-center gap-0">
                  <Typography.Text className="bold">
                    {user?.suspended}
                  </Typography.Text>
                  <Typography.Text>Suspended</Typography.Text>
                </div>
              </Tooltip>
            </Space>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="flex-column"
            title="Company Count"
            extra={
              <Button
                type="ghost"
                onClick={() => navigate("/admin/companies")}
                size="small"
              >
                View
              </Button>
            }
          >
            <Statistic
              value={company?.total || 0}
              valueStyle={{ textAlign: "center" }}
              className="mb-2"
            />
            <Space
              split={<Divider type="vertical" />}
              className="full-width justify-center"
            >
              <Tooltip title="Companies with active or due status">
                <div className="flex-column justify-center text-center gap-0">
                  <Typography.Text className="bold">
                    {company?.active || 0}
                  </Typography.Text>
                  <Typography.Text>Active</Typography.Text>
                </div>
              </Tooltip>
              <Tooltip title="Companies with suspended status">
                <div className="flex-column justify-center text-center gap-0">
                  <Typography.Text className="bold">
                    {company?.suspended || 0}
                  </Typography.Text>
                  <Typography.Text>Suspended</Typography.Text>
                </div>
              </Tooltip>
            </Space>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="flex-column"
            title="Task Count"
            extra={
              <Button
                type="ghost"
                onClick={() => navigate("/admin/tasks")}
                size="small"
              >
                View
              </Button>
            }
          >
            <Statistic
              value={task?.total || 0}
              valueStyle={{ textAlign: "center" }}
              className="mb-2"
            />
            <Space
              split={<Divider type="vertical" />}
              className="full-width justify-center"
            >
              <Tooltip title="Tasks that are in-progress or awaiting verification">
                <div className="flex-column justify-center text-center gap-0">
                  <Typography.Text className="bold">
                    {task?.active || 0}
                  </Typography.Text>
                  <Typography.Text>Active</Typography.Text>
                </div>
              </Tooltip>
              <Tooltip title="Tasks that are awaiting payment">
                <div className="flex-column justify-center text-center gap-0">
                  <Typography.Text className="bold">
                    {task?.awaitingPayment || 0}
                  </Typography.Text>
                  <Typography.Text>Awaiting Payment</Typography.Text>
                </div>
              </Tooltip>
            </Space>
          </Card>
        </Col>
      </Row>

      <Typography.Title level={5} className="mt-3">
        Entity Count Per Status
      </Typography.Title>

      <Row gutter={5}>
        <Col span={8}>
          <Card title="User/Member">
            <Column
              data={userCount}
              xField={"status"}
              yField={"count"}
              colorField={"status"}
              scale={500}
              legend={null}
              label={{
                text: (d) => `${d.count}`,
                textBaseline: "bottom",
              }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Tasks">
            <Column
              data={taskCount}
              xField={"status"}
              yField={"count"}
              colorField={"status"}
              scale={500}
              legend={null}
              label={{
                text: (d) => `${d.count}`,
                textBaseline: "bottom",
              }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Company"
            extra={
              <Button
                size="small"
                type="ghost"
                onClick={() => {
                  navigate("/admin/companies");
                }}
              >
                View
              </Button>
            }
          >
            <Column
              data={companyCount}
              xField={"status"}
              yField={"count"}
              colorField={"status"}
              scale={500}
              legend={null}
              label={{
                text: (d) => `${d.count}`,
                textBaseline: "bottom",
              }}
            />
          </Card>
        </Col>
      </Row>

      <Card
        title="New Applications / Withdrawn / Renewals"
        extra={
          <Button
            type="primary"
            onClick={() => {
              navigate("/admin/reports");
            }}
          >
            View Report
          </Button>
        }
      >
        <Column
          data={entitySummary}
          xField={"month"}
          yField={"count"}
          colorField={"name"}
          scale={500}
          legend={{
            capture: false,
          }}
          group
          groupField="name"
          label={{
            text: (d) => `${d.count}`,
            textBaseline: "bottom",
          }}
        />
      </Card>
    </div>
  );
}

export default Dashboard;
