import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Row, Col, Select, DatePicker } from "antd";
import { useLazyQuery } from "@apollo/client";
import { GET_COMPANIES_BY_FILTER } from "../../../graphql/queries";
import { SEARCH_EMPLOYEES } from "../graphql/queries";
import { useGlobalState } from "../../../globalStore";
import {
  months,
  TASK_STATUS_TYPES,
  USER_STATUS_TYPES,
} from "../../../constants";
import { format, isFuture } from "date-fns";
import { generateMonths } from "@/utils";

const { Option } = Select;

export const CompanyFilters = (
  { handleFilterChange, defaultMultiFilter, setError, type, companyId },
  ref
) => {
  const { state } = useGlobalState();
  const [companyList, setCompanyList] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [customReportTypeVal, setCustomReportTypeVal] = useState<string>();
  const [employeeStatusesVal, setEmployeeStatusesVal] = useState<string[]>();
  const [ageStartVal, setAgeStartVal] = useState(0);
  const [ageEndVal, setAgeEndVal] = useState(0);

  const companyFilterRef = useRef();
  const statusFilterRef = useRef();
  const typeFilterRef = useRef();
  const sortFilterRef = useRef();
  const monthFilterRef = useRef();
  const dateRangeFilterRef = useRef();
  const employeeFilterRef = useRef();
  const complianceReportTypeFilterRef = useRef();
  const customReportTypeFilterRef = useRef();

  const clearState = (includeReportType = false) => {
    console.log(`customReportTypeVal`, customReportTypeVal);
    setCompanyList([]);
    setAgeEndVal(0);
    setAgeStartVal(0);

    setEmployeeStatusesVal(undefined);
    if (includeReportType) setCustomReportTypeVal(undefined);
  };

  useImperativeHandle(ref, () => ({
    // @ts-ignore: function called from parent
    resetFilters() {
      clearState(true);
      resetFilterCollection([
        {
          ref: companyFilterRef,
          defaultValue: defaultMultiFilter["company"],
        },
        {
          ref: typeFilterRef,
          defaultValue: defaultMultiFilter["type"],
        },
        {
          ref: statusFilterRef,
          defaultValue: defaultMultiFilter["status"],
        },
        {
          ref: sortFilterRef,
          defaultValue: defaultMultiFilter["sort"],
        },
        {
          ref: monthFilterRef,
          defaultValue: defaultMultiFilter["month"],
        },
        {
          ref: employeeFilterRef,
          defaultValue: defaultMultiFilter["employee"],
        },
      ]);
    },
  }));

  const resetFilterCollection = (refMetaCollection = []) => {
    for (let refMeta of refMetaCollection) {
      if (refMeta && refMeta.ref && refMeta.ref.current) {
        let currentRef = refMeta.ref.current;
        let useDefaultValue = refMeta.defaultValue
          ? refMeta.defaultValue
          : null;
        if (currentRef.rcSelect) {
          currentRef.rcSelect.setState({
            value: Array.isArray(useDefaultValue)
              ? useDefaultValue
              : [useDefaultValue],
          });
        } else if (currentRef.picker) {
          currentRef.picker.setState({
            value: useDefaultValue,
            showDate: useDefaultValue,
          });
        } else if (currentRef.input) {
          currentRef.input.value = useDefaultValue;
        }
      }
    }
  };

  const [searchCompanies] = useLazyQuery(GET_COMPANIES_BY_FILTER, {
    onError: (err) => {
      setLoadingCompanies(false);
      console.log("Company Search Err", err);
    },
    onCompleted: (data) => {
      setCompanyList(data.companiesByFilter?.companyList);
      setLoadingCompanies(false);
    },
  });

  const handleCompanySearch = (value: string) => {
    if (value.length > 2) {
      setLoadingCompanies(true);
      searchCompanies({ variables: { criteria: "searchText", filter: value } });
    }
  };

  const [searchEmployees] = useLazyQuery(SEARCH_EMPLOYEES, {
    onError: () => {
      setLoadingEmployees(false);
    },
    onCompleted: ({ searchCompanyEmployee }) => {
      setLoadingEmployees(false);
      if (searchCompanyEmployee) setEmployeeList(searchCompanyEmployee);
    },
  });

  const handleEmployeeSearch = (value) => {
    if (value.length > 2 && companyId) {
      setLoadingEmployees(true);
      searchEmployees({
        variables: { input: { searchTerm: value, companyId: companyId } },
      });
    }
  };

  const formatMonth = (m: string) =>
    `${months[+m.split("-")[0] - 1]} ${m.split("-")[1]}`.toUpperCase();

  const years = useMemo<number[]>(() => {
    const _years = [];
    for (let i = 2010; i <= 2030; i++) {
      _years.push(i);
    }
    return _years;
  }, []);

  const currentYear = new Date().getFullYear();

  const TypeFilter = (
    <div className="flex-center-space-between">
      <label>Type:</label>
      <Select
        ref={typeFilterRef}
        placeholder="Select company type"
        onChange={(value) => handleFilterChange(value, "type")}
        style={{ width: "calc(100% - 75px)" }}
        defaultValue="Subscription Control List"
      >
        <Option value="Subscription Control List">
          Subscription Control List
        </Option>
        <Option value="Future Subscription Control List">
          Future Subscription Control List
        </Option>
        <Option value="Renewal Fees Due">Renewal Fees Due</Option>
        <Option value="Certificate Run">Certificate Run</Option>
        <Option value="Compliance Report">Compliance Report</Option>
        <Option value="Audit Certificate Submissions">
          Audit Certificate Submissions
        </Option>
        <Option value="Custom Report">Custom Report</Option>
      </Select>
    </div>
  );

  const DateRangeFilter = (
    <div className="flex-center-space-between">
      <label>Date Range:</label>
      <DatePicker.RangePicker
        ref={dateRangeFilterRef}
        disabledDate={(current) => {
          return isFuture(current.toDate());
        }}
        onChange={([from, to]) => {
          handleFilterChange([from.toDate(), to.toDate()], "dateRange");
        }}
      />
    </div>
  );

  const CompanyFilter = (
    <div className="flex-center-space-between">
      <label>Company:</label>
      <Select
        ref={companyFilterRef}
        placeholder="Please select company"
        mode={
          type === "Future Subscription Control List" ? undefined : "multiple"
        }
        defaultActiveFirstOption={false}
        showSearch
        showArrow={false}
        filterOption={false}
        notFoundContent={null}
        style={{ width: "calc(100% - 75px)" }}
        onSearch={handleCompanySearch}
        onChange={(val: string[] | string) => {
          handleFilterChange(
            typeof val === "string"
              ? [parseInt(val)]
              : val.map((i) => parseInt(i)),
            "company"
          );
        }}
        loading={loadingCompanies}
      >
        {companyList.map((d) => (
          <Select.Option key={d.id}>
            {d.registeredName || d.tradingName}
          </Select.Option>
        ))}
      </Select>
    </div>
  );

  const ComplianceReportTypeFilter = (
    <div className="flex-center-space-between">
      <label>Compliance Report Type:</label>
      <Select
        ref={complianceReportTypeFilterRef}
        placeholder="Select type"
        onChange={(value) => handleFilterChange(value, "complianceReportType")}
        style={{ width: "calc(100% - 75px)" }}
      >
        <Option value="business">Business</Option>
        <Option value="debtCollector">Debt Collector</Option>
      </Select>
    </div>
  );

  const CustomReportTypeFilter = (
    <div className="flex-center-space-between">
      <label>Custom Report Type:</label>
      <Select
        ref={customReportTypeFilterRef}
        placeholder="Select type"
        onChange={(value: string) => {
          clearState();
          setCustomReportTypeVal(value);
          handleFilterChange(value, "customReportType");
        }}
        style={{ width: "calc(100% - 75px)" }}
      >
        <Option value="Company">Company</Option>
        <Option value="Member">Member</Option>
        <Option value="Audit">Audit</Option>
      </Select>
    </div>
  );

  const MonthFilter = (
    <div className="flex-center-space-between">
      <label>Month: </label>
      <Select
        ref={monthFilterRef}
        disabled={
          ![
            "Subscription Control List",
            "Future Subscription Control List",
            "Renewal Fees Due",
          ].includes(type)
        }
        placeholder="Select the month(s)"
        onChange={(value) => handleFilterChange(value, "month")}
        style={{ width: "calc(100% - 75px)" }}
        mode={
          type === "Future Subscription Control List" ? "multiple" : undefined
        }
      >
        {(type === "Future Subscription Control List"
          ? generateMonths()
          : state.lookups?.renewalMonths || []
        ).map((m, i) => (
          <Option key={`month-${i}`} value={m}>
            {type === "Future Subscription Control List"
              ? format(new Date(m), "MMMM yyyy")
              : formatMonth(m)}
          </Option>
        ))}
      </Select>
    </div>
  );

  const SortFilter = (
    <div className="flex-center-space-between">
      <label>Sort:</label>
      <Select
        ref={sortFilterRef}
        placeholder="sort by..."
        onChange={(value) => handleFilterChange(value, "sort")}
        style={{ width: "calc(100% - 75px)" }}
        defaultValue={defaultMultiFilter["sort"]}
      >
        <Option value="DESC">Newest</Option>
        <Option value="ASC">Oldest</Option>
        <Option value="name_ASC">Name ASC</Option>
        <Option value="name_DESC">Name DESC</Option>
      </Select>
    </div>
  );

  const YearFromFilter = (
    <div className="flex-center-space-between">
      <label>From:</label>
      <Select
        ref={sortFilterRef}
        placeholder="from"
        onChange={(value) => handleFilterChange(value, "from")}
        style={{ width: "calc(100% - 75px)" }}
        defaultValue={currentYear - 1}
      >
        {years.map((y) => (
          <Option value={y}>{y}</Option>
        ))}
      </Select>
    </div>
  );

  const YearToFilter = (
    <div className="flex-center-space-between">
      <label>To:</label>
      <Select
        ref={sortFilterRef}
        placeholder="to"
        onChange={(value) => handleFilterChange(value, "to")}
        style={{ width: "calc(100% - 75px)" }}
        defaultValue={currentYear}
      >
        {years.map((y) => (
          <Option value={y}>{y}</Option>
        ))}
      </Select>
    </div>
  );

  const StatusFilter = (
    <div className="flex-center-space-between">
      <label>Status:</label>
      <Select
        ref={statusFilterRef}
        placeholder="Select status"
        onChange={(value: string) => {
          handleFilterChange(value, "status");
        }}
        style={{ width: "calc(100% - 75px)" }}
        disabled={type === "Compliance Report"}
      >
        {type === "Certificate Run" &&
          ["Active", "Deregistered"].map((i) => (
            <Option key={i} value={i.toLowerCase()}>
              {i}
            </Option>
          ))}
        {type === "Subscription Control List" &&
          TASK_STATUS_TYPES.map((i) => (
            <Option key={i.name} value={i.name}>
              {i.name}
            </Option>
          ))}
        {type === "Renewal Fees Due" &&
          state.lookups?.transactionStatuses.map((i) => (
            <Option key={i.name} value={i.name}>
              {i.name}
            </Option>
          ))}
        {type === "Custom Report" &&
          USER_STATUS_TYPES.map((i) => (
            <Option key={i} value={i}>
              {i}
            </Option>
          ))}
      </Select>
    </div>
  );

  const EmployeeFilter = (
    <div className="flex-center-space-between">
      <label>Employee:</label>
      <Select
        ref={employeeFilterRef}
        placeholder="Search employee"
        defaultActiveFirstOption={false}
        showArrow
        filterOption={false}
        notFoundContent={null}
        showSearch
        style={{ width: "calc(100% - 75px)" }}
        onSearch={handleEmployeeSearch}
        onChange={(val: string[]) => {
          handleFilterChange(+val, "employee");
        }}
        loading={loadingEmployees}
      >
        {employeeList.map((d) => (
          <Select.Option key={d.id} value={d.id}>
            {`${d.firstName} ${d.lastName}`.toUpperCase()}
          </Select.Option>
        ))}
      </Select>
    </div>
  );

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={type === "Custom Report" ? 12 : 8}>{TypeFilter}</Col>
        {type === "Audit Certificate Submissions" && (
          <>
            <Col span={8}>{CompanyFilter}</Col>
            <Col span={4}>{YearFromFilter}</Col>
            <Col span={4}>{YearToFilter}</Col>
          </>
        )}
        {type === "Compliance Report" && (
          <>
            <Col span={8}>{ComplianceReportTypeFilter}</Col>
            <Col span={8}>{DateRangeFilter}</Col>
          </>
        )}
        {![
          "Compliance Report",
          "Custom Report",
          "Audit Certificate Submissions",
        ].includes(type) && (
          <>
            <Col span={8}>{CompanyFilter}</Col>
            <Col span={8}>{MonthFilter}</Col>
          </>
        )}
        {type === "Custom Report" && (
          <Col span={12}>{CustomReportTypeFilter}</Col>
        )}
      </Row>
      <Row gutter={[24, 24]}>
        {![
          "Compliance Report",
          "Custom Report",
          "Audit Certificate Submissions",
          "Future Subscription Control List",
        ].includes(type) && (
          <>
            <Col span={8}>{SortFilter}</Col>
            <Col span={8}>{StatusFilter}</Col>
          </>
        )}
        {type === "Certificate Run" && <Col span={8}>{EmployeeFilter}</Col>}
      </Row>
      <Row gutter={[24, 24]}>
        {type === "Custom Report" && (
          <>
            {((ageStartVal > 0 && ageEndVal > 0) || employeeStatusesVal) && (
              <Col span={8}>{DateRangeFilter}</Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default forwardRef(CompanyFilters);
