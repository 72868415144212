import { Button, Col, Input, Row, Select, Form } from "antd";
import { CREATE_MANUAL_REG_INVITATION_MUTATION } from "@/graphql/mutations";
import {
  GET_COMPANIES_BY_FILTER,
  GET_COMPANY,
} from "../../../../graphql/queries";

import { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { notifyError, openNotificationWithIcon } from "@/utils/notification";
import cfdcLogo from "../../../../assets/images/cfdc-logo.png";
import {
  TERMS,
  MANUAL_REGISTER_ID,
  ADMIN_STREAMS,
} from "../../../../constants";
import { useGlobalState } from "../../../../globalStore";
import { useRegistrationState } from "@/stores/registration";

function RegisterForm() {
  const [form] = Form.useForm();
  const { setGlobalState } = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [companyId, setCompanyId] = useState<number>();
  const role = localStorage.getItem("userRoles");

  const setRegistrationState = useRegistrationState(
    (s) => s.setRegistrationStateObject
  );

  const [createManualRegInvitation] = useMutation(
    CREATE_MANUAL_REG_INVITATION_MUTATION,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ createManualRegInvitation }) => {
        const { user, invitation, alreadyExists, isTransferable } =
          createManualRegInvitation;

        const employment = user?.employment?.find(
          ({ company }) => company?.id === companyId
        );

        if (employment) {
          openNotificationWithIcon(
            "warning",
            "Note",
            `${user.firstName} is already employed at ${employment.company.registeredName}`
          );
          form.resetFields();
          return;
        }

        const stateObj: any = {
          manualRegistrationType: "new",
          manualRegistrationInvitationId: invitation.id,
          [MANUAL_REGISTER_ID]: user.id,
          manualRegisterUser: user,
          ...(employment && { isMultipleEmployment: true }),
          isExistingUser: alreadyExists,
          isTransferable,
          manualRegisterCompanyId: companyId,
        };

        setGlobalState(stateObj);

        setRegistrationState(stateObj);
      },
      onError: (error) => {
        console.error("create_manual_invitation_error", error);
        openNotificationWithIcon(
          "error",
          "Get User Error",
          "Failed to retrieve user details. Please try again later"
        );
        setLoading(false);
      },
    }
  );

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      delete values.confirm_password;
      if (
        values.phone.substring(0, 3) === "027" ||
        values.phone.substring(0, 3) === "+27"
      ) {
        values.phone = "+27" + values.phone.slice(3);
      } else if (values.phone.substring(0, 1) === "0") {
        values.phone = "+27" + values.phone.slice(1);
      } else if (values.phone.substring(0, 3) !== "+27") {
        values.phone = "+27" + values.phone;
      }

      await createManualRegInvitation({
        variables: {
          input: {
            idNumber: values.idNumber,
            email: `${values.idNumber}@fake.com`,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            creatorUserId: +localStorage.getItem("userId"),
            companyId,
          },
        },
      });
    } catch (error) {
      return notifyError(error);
    }
  };

  const [getCompany] = useLazyQuery(GET_COMPANY, {
    variables: {
      input: { id: +localStorage.getItem("companyId") },
    },
    onError: (error) => {
      notifyError(error);
    },
    onCompleted: ({ company }) => {
      setCompaniesList([company]);
    },
  });

  const [searchCompanies] = useLazyQuery(GET_COMPANIES_BY_FILTER, {
    fetchPolicy: "network-only",
    variables: {},
    onError: (error) => {
      console.error("get_companies_by_filter_error", error);
      openNotificationWithIcon(
        "error",
        "Error",
        "Error loading employer information"
      );
    },
    onCompleted: (data) =>
      setCompaniesList(data.companiesByFilter?.companyList),
  });

  const handleCompaniesSearch = (value) => {
    if (value.length > 2) {
      searchCompanies({ variables: { criteria: "searchText", filter: value } });
    }
  };

  const companiesOptions = companiesList.map((d) => (
    <Select.Option key={d.id}>
      {d.registeredName || d.tradingName}
    </Select.Option>
  ));

  const setRegistrationNumberField = (e) => {
    const company = companiesList.find((x) => x.id === Number.parseInt(e));
    setCompanyId(company.id);
  };

  useEffect(() => {
    if (!ADMIN_STREAMS.includes(role)) getCompany();
  }, []);

  const terms = (
    <Col
      span={10}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h3>By signing up for registration I agree to the following:</h3>
      <ol style={{ fontSize: "13px" }}>
        {TERMS.map((term, index) => (
          <li style={{ marginBottom: "12px" }} key={index}>
            {term.replace("a Business Entity", "an Employee")}
          </li>
        ))}
      </ol>
    </Col>
  );

  const logo = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ textAlign: "center" }}>
        <img src={cfdcLogo} alt="cfdc" className="logo-register" />
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: "2vh",
          marginBottom: "1vh",
        }}
      >
        <h2>Sign Up to Council Smart</h2>
      </div>
    </div>
  );

  const fields = (
    <Col
      span={14}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Form.Item
        label="ID/Passport Number"
        className="form-item"
        hasFeedback
        name="idNumber"
        required
      >
        <Input placeholder="Please enter your ID or passport number" />
      </Form.Item>

      <Form.Item
        label="First Name"
        className="form-item"
        hasFeedback
        name="firstName"
        required
        normalize={(s) => s?.toUpperCase()}
      >
        <Input placeholder="Please enter your name" />
      </Form.Item>

      <Form.Item
        label="Last Name"
        className="form-item"
        hasFeedback
        name="lastName"
        required
        normalize={(s) => s?.toUpperCase()}
      >
        <Input placeholder="Please enter your name" />
      </Form.Item>

      <Form.Item
        label="Phone Number"
        className="form-item"
        hasFeedback
        name={"phone"}
        required
      >
        <Input
          addonBefore={
            <div>
              <span style={{ color: "black" }}>🇿🇦</span> +27
            </div>
          }
          placeholder="Please enter your phone"
        />
      </Form.Item>

      <Form.Item
        label="Company"
        className="form-item"
        name={"employer"}
        required
      >
        <Select
          showSearch
          placeholder="Enter company name"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          notFoundContent={null}
          onSearch={handleCompaniesSearch}
          onChange={setRegistrationNumberField}
        >
          {companiesOptions}
        </Select>
      </Form.Item>
      <Form.Item {...{ name: "" }}>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-register"
          style={{ marginBottom: "50px" }}
          loading={loading}
        >
          Sign Up
        </Button>
      </Form.Item>
    </Col>
  );

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      className="register-form"
    >
      <Row>{logo}</Row>
      <Row gutter={[10, 10]} style={{ marginTop: "20px", overflowY: "scroll" }}>
        {terms}
        {fields}
      </Row>
    </Form>
  );
}

export default RegisterForm;
