import { Table, Badge } from "antd";
import { format } from "date-fns";

type Props = {
  auditList: any[];
  currentPage: number;
  pageSize: number;
  listTotal: number;
  handlePagination: (page: number) => void;
};
export default ({
  auditList,
  currentPage,
  pageSize,
  listTotal,
  handlePagination,
}: Props) => {
  const columns = [
    {
      title: "Created",
      key: "dateCreated",
      dataIndex: "dateCreated",
      render: (record) => format(new Date(record), "dd/MM/yyyy"),
    },
    {
      title: "Company",
      key: "task.company",
      dataIndex: "company",
      render: (record) =>
        (record.tradingName || record.registeredName).toUpperCase(),
    },
    {
      title: "BBEEE Level",
      key: "bbeeeLevel",
      render: (record) => record.bbeeeLevel,
    },
    {
      title: "Industry",
      render: (record) => record.industry,
      key: "industry",
    },
    {
      title: "Geographical Area",
      render: (record) => record.geographicalArea,
      key: "geographicalArea",
    },
    {
      title: "Value of Books",
      render: (record) => record.valueOfBooks,
      key: "valueOfBooks",
    },
    {
      title: "Amount Recovered",
      render: (record) => record.amountRecovered,
      key: "amountRecovered",
    },
    {
      title: "Task ID",
      render: (record) => record.task.id,
      key: "task.id",
    },
    {
      title: "Status",
      dataIndex: "taskStatus",
      key: "status",
      render: (record) => {
        const status =
          record.charAt(0).toUpperCase() + record.slice(1).toLowerCase();
        return record ? (
          <span style={{ display: "flex" }}>
            <Badge status={status === "Active" ? "success" : "warning"} />{" "}
            {status}
          </span>
        ) : (
          ""
        );
      },
    },
    // {
    //   title: <span>Action</span>,
    //   key: "action",
    //   render: record => {
    //     const items = [
    //       <Menu.Item
    //         key="View user profile"
    //         onClick={() => navigate(`/admin/company/${record.id}`)}
    //       >
    //         View company
    //       </Menu.Item>
    //     ];

    //     return (
    //       <Dropdown
    //         overlay={<Menu>{items.map(i => i)}</Menu>}
    //         placement="bottomRight"
    //         trigger={["click"]}
    //       >
    //         <span className="ant-dropdown-link purple-link">
    //           More
    //           <Icon type="down" title="member actions dropdown" />
    //         </span>
    //       </Dropdown>
    //     );
    //   }
    // }
  ];
  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={{
        current: currentPage,
        showSizeChanger: false,
        pageSize,
        onChange: (page) => handlePagination(page),
        total: listTotal,
        showTotal: () => <h3>Total: {listTotal}</h3>,
      }}
      dataSource={auditList}
    />
  );
};
