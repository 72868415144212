import { useState, useEffect } from "react";
import { CheckCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import { Card, Table, Button, Divider, Tag } from "antd";
import { format, parseISO } from "date-fns";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { BATCH_REGISTRATION_QUERY } from "../../../../graphql/queries";
import BatchFilters from "./BatchFilters";
import InternalErrorPage from "../../../InternalErrorPage";
import { writeCSV } from "@/utils";
import { notifyError, notifySuccess } from "@/utils/notification";
import { useBatchFilters } from "@/hooks/UseBatchFilters";
import { COMPLETE_BATCH_REGISTRATION } from "@/graphql/mutations";
import { useRegistrationState } from "@/stores/registration";
import { useUserState } from "@/stores/user";

function BatchRegistrationList(props: any) {
  const [downloading, setDownloading] = useState(false);
  const {
    page,
    take,
    skip,
    completed,
    id,
    startDate,
    endDate,
    setFilterState,
    clearFilters,
  } = useBatchFilters();
  const clearRegState = useRegistrationState((s) => s.clearRegistrationState);
  const isSuperAdmin = useUserState((s) => s.isSuperAdmin);

  const { refetch: downloadBatches } = useQuery(BATCH_REGISTRATION_QUERY, {
    fetchPolicy: "no-cache",
    skip: true,
  });

  const [complete, { loading: completing }] = useMutation(
    COMPLETE_BATCH_REGISTRATION,
    {
      onCompleted: (data: any) => {
        clearRegState(true);
        runMultiFilterQuery();
        if (data.completeBatchRegistration?.completed) {
          notifySuccess("Batch registration completed successfully");
        } else {
          notifyError("Failed to complete batch registration");
        }
      },
      onError: (err: any) => {
        notifyError(err);
      },
    }
  );

  const [getBatchRegistrations, { data, loading, error }] = useLazyQuery(
    BATCH_REGISTRATION_QUERY,
    {
      fetchPolicy: "no-cache",
    }
  );

  const { batchRegistrations = [], total = 0 } =
    data?.getBatchRegistrationByFilters || {};

  const runMultiFilterQuery = () => {
    const input: any = {
      skip,
      take,
    };

    if (id) {
      input.id = +id;
    }

    if (completed) {
      input.completed = completed === "true";
    }

    if (startDate) {
      input.startDate = startDate;
    }

    if (endDate) {
      input.endDate = endDate;
    }

    getBatchRegistrations({
      variables: {
        input,
      },
    });
  };

  async function handleDownload() {
    try {
      setDownloading(true);
      const data = [];
      let t = 100;
      let s = 0;

      while (data.length < total) {
        const { data: d, errors } = await downloadBatches({
          input: {
            skip: s,
            take: t,
          },
        });

        if (errors && errors.length) {
          setDownloading(false);
          return notifyError(errors[0].message);
        }

        data.push(
          ...(d.getBatchRegistrationByFilters.batchRegistrations || [])
        );
        s += t;
      }

      writeCSV(
        "Batch Registrations",
        `Batch Registrations ${format(new Date(), "dd-MM-yyyy")}`,
        data.map((t) => {
          return {
            "#": t.id,
            Date: format(parseISO(t.dateCreated), "dd-MM-yyyy"),
            "Financial Task ID": t.financialTaskId || "",
            "Employee Task IDs": t.tasks?.join(" ") || "",
            Status: t.completed ? "Completed" : "In Progress",
          };
        })
      );
    } catch (err) {
      // @ts-ignore
      notifyError(err);
    } finally {
      setDownloading(false);
    }
  }

  useEffect(() => {
    setFilterState({ page: "1" });
  }, [total]);

  useEffect(() => {
    runMultiFilterQuery();
  }, []);

  useEffect(() => {
    runMultiFilterQuery();
  }, [page, skip, take, completed, id, startDate, endDate]);

  const columns = [
    {
      title: "#",
      key: "id",
      render: (record) => record.id,
    },
    {
      title: "Date",
      key: "date",
      render: ({ dateCreated }) => format(parseISO(dateCreated), "yyyy-MM-dd"),
    },
    {
      title: "Financial Task",
      key: "financialTaskId",
      render: ({ financialTaskId }) => financialTaskId,
    },
    {
      title: "Employee Tasks",
      key: "firstName",
      render: ({ tasks }) => tasks?.join(", ") || "",
    },
    {
      title: "Status",
      key: "status",
      render: ({ completed }) =>
        completed ? (
          <Tag color="#87d068">Completed</Tag>
        ) : (
          <Tag color="#2db7f5">In Progress</Tag>
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: any) => {
        if (!isSuperAdmin()) return null;

        return (
          <Button
            type="link"
            icon={<CheckCircleOutlined />}
            disabled={record.completed || completing}
            loading={completing}
            onClick={() => {
              complete({
                variables: {
                  input: { id: record.id },
                },
              });
            }}
          >
            Complete
          </Button>
        );
      },
    },
  ];

  if (error) {
    return <InternalErrorPage error={error.message} />;
  }

  return (
    <div
      className="placeholder-table-card"
      style={{ width: "calc(100% - 20px" }}
    >
      <Card>
        <div className="card-header">
          <h3>Batch Registration Tasks</h3>
          <div>
            <Button
              icon={<DownloadOutlined />}
              className="purple-button"
              onClick={handleDownload}
              loading={downloading}
            >
              Download
            </Button>
            <Button className="purple-button" onClick={clearFilters}>
              Clear filters
            </Button>
          </div>
        </div>
        <div className={loading ? "no-interact" : null}>
          <BatchFilters />
          <Divider />
          <Table
            loading={loading}
            rowKey={(record: any) => record.id}
            columns={columns}
            pagination={{
              current: page,
              showSizeChanger: false,
              pageSize: take,
              onChange: (page: number) => {
                setFilterState({ page: String(page) });
              },
              total,
              showTotal: () => <h3>Total: {total}</h3>,
            }}
            dataSource={batchRegistrations}
          />
        </div>
      </Card>
    </div>
  );
}

export default BatchRegistrationList;
