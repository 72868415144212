import { DownOutlined } from "@ant-design/icons";
import { Table, Badge, Menu, Dropdown } from "antd";
import { NavigateFunction } from "react-router-dom";
import { format, max } from "date-fns";

type Props = {
  companyList: any[];
  currentPage: number;
  pageSize: number;
  listTotal: number;
  handlePagination: (page: number) => void;
  navigate: NavigateFunction;
};

export default ({
  companyList,
  currentPage,
  pageSize,
  listTotal,
  handlePagination,
  navigate,
}: Props) => {
  const columns = [
    {
      title: "Company",
      key: "company",
      render: (record) =>
        (record.registeredName
          ? record.registeredName
          : record.tradingName
        ).toUpperCase(),
    },
    {
      title: "# of Users",
      key: "userCount",
      render: (record) => record.employmentCount,
    },
    {
      title: "Membership #",
      render: (record) => record.membershipNumber,
      key: "membershipNumber",
    },
    {
      title: "Valid Until",
      render: ({ certificates }) => {
        if (!certificates || certificates.length === 0) return "N/A";
        return format(
          max(certificates.map(({ toDate }) => new Date(toDate))),
          "yyyy-MM-dd"
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record) => {
        const status =
          record.charAt(0).toUpperCase() + record.slice(1).toLowerCase();
        return record ? (
          <span style={{ display: "flex" }}>
            <Badge status={status === "Active" ? "success" : "warning"} />{" "}
            {status}
          </span>
        ) : (
          ""
        );
      },
    },
    {
      title: <span>Action</span>,
      key: "action",
      render: (record) => {
        const items = [
          <Menu.Item
            key="View user profile"
            onClick={() => navigate(`/admin/company/${record.id}`)}
          >
            View company
          </Menu.Item>,
        ];

        return (
          <Dropdown
            overlay={<Menu>{items.map((i) => i)}</Menu>}
            placement="bottomRight"
            trigger={["click"]}
          >
            <span className="ant-dropdown-link purple-link">
              More
              <DownOutlined title="member actions dropdown" />
            </span>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={{
        current: currentPage,
        showSizeChanger: false,
        pageSize,
        onChange: (page) => handlePagination(page),
        total: listTotal,
        showTotal: () => <h3>Total: {listTotal}</h3>,
      }}
      dataSource={companyList}
    />
  );
};
