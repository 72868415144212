import { Radio } from "antd";

import RegistrationCompany from "../RegisterCompany/registration";
import RegistrationSoleProprietor from "../RegisterSoleProprietor/registration";
import { useRegistrationState } from "@/stores/registration";
import { If } from "@/components/If";

function BusinessRegistrationForm(props: any) {
  const {
    setRegistrationStateObject: setRegistrationState,
    registeringAs,
    registrationCapacity,
  } = useRegistrationState();

  const isBusiness = registrationCapacity === "Business";

  return (
    <div className="flex-column">
      <If condition={isBusiness}>
        <div className="business-basic-details">
          <Radio.Group
            onChange={(e) => {
              setRegistrationState({ registeringAs: e.target.value });
            }}
            value={registeringAs}
          >
            <Radio value={"Company"}>Company</Radio>
            <Radio value={"Closed Corporation"}>Closed Corporation</Radio>
            <Radio value={"Partnership"}>Partnership</Radio>
            <Radio value={"A Sole Proprietor"}>A Sole Proprietor</Radio>
            <Radio value={"Co-operative"}>Co-operative</Radio>
            <Radio value={"Trust"}>Trust</Radio>
          </Radio.Group>

          <If condition={!!registeringAs}>
            {registeringAs === "A Sole Proprietor" ? (
              <RegistrationSoleProprietor />
            ) : (
              <RegistrationCompany registeredAs={registeringAs} />
            )}
          </If>
        </div>
      </If>
    </div>
  );
}

export default BusinessRegistrationForm;
