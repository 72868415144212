import { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { InboxOutlined } from "@ant-design/icons";
import { Modal, Select, Upload, message, Form } from "antd";
import { uploadToS3 } from "@/utils/uploadToS3";
import { CREATE_DOCUMENT } from "../../../graphql/mutations";
import {
  GET_PRESIGNED_URL,
  GET_COMPANIES_BY_FILTER,
} from "../../../graphql/queries";
import { isEmpty } from "lodash";
import { ADMIN_STREAMS } from "../../../constants";
import { useGlobalState } from "@/globalStore";
import { generateArrayOfYears } from "@/utils";
const { Dragger } = Upload;
const { Option } = Select;

function CreateDocumentForm({
  visible,
  onCancel,
  clearFilters,
  memberId,
  memberIdNumber,
  companyId,
  companyName,
}) {
  const [form] = Form.useForm();
  const { state } = useGlobalState();
  const [file, setFile] = useState<any>();
  const [contentType, setContentType] = useState("");
  const [documentsToUpload, setDocumentsToUpload] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const userRole = localStorage.getItem("userRoles");

  const [submitDocuments] = useMutation(CREATE_DOCUMENT);

  const [getPresignedUrl] = useLazyQuery(GET_PRESIGNED_URL, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!isEmpty(data.preSignedUrl)) {
        const docList = documentsToUpload;
        docList.push({
          documentName,
          preSignedUrl: data.preSignedUrl,
          file,
          contentType,
        });
        setDocumentsToUpload(docList);
      }
    },
  });

  //selectable options of project status
  const documentTypeOptions = state.lookups?.documentTypes.map((type) => {
    return (
      <Option key={type.id} value={type.id}>
        {type.extendedName}
      </Option>
    );
  });

  const companyNameOptions = companyList.map((d) => (
    <Select.Option key={d.id}>
      {d.registeredName || d.tradingName}
    </Select.Option>
  ));

  const [searchCompanies] = useLazyQuery(GET_COMPANIES_BY_FILTER, {
    fetchPolicy: "network-only",
    variables: {},
    onError: (error) => {
      setLoadingCompanies(false);
      console.error(error);
    },
    onCompleted: (data) => {
      setCompanyList(data.companiesByFilter?.companyList);
      setLoadingCompanies(false);
    },
  });

  const handleCompaniesSearch = (value) => {
    if (value.length > 2) {
      setLoadingCompanies(true);
      searchCompanies({ variables: { criteria: "searchText", filter: value } });
    }
  };

  const uploadDocuments = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          // upload to s3
          setUploading(true);
          await Promise.all(
            documentsToUpload.map(async (doc) => {
              const { name, umbrella } = state.lookups?.documentTypes?.find(
                (t) => t.id === +values.documentTypeId
              );
              await uploadToS3(doc.preSignedUrl, doc.contentType, doc.file);

              const documentName: string = values.auditYear
                ? `${doc.documentName} - ${values.auditYear}`
                : doc.documentName;

              const inputVariables = {
                documentName: `${values.companyId}_${umbrella}_${name} - ${documentName}`,
                documentTypeId: values.documentTypeId,
                documentStatusId: 1,
                standardDescription: documentName,
                altDescription: "",
                documentFormat: doc.contentType,
                companyId: +values.companyId,
                forCompany: memberId ? false : true,
                auditFinancialYear: values.auditYear,
              };

              if (memberId) {
                inputVariables["userId"] = memberId;
              }

              await submitDocuments({
                variables: {
                  input: inputVariables,
                },
              });
              message.success(`${file.name} uploaded successfully`);
              form.resetFields();
              onCancel();
              setFile(null);
              setContentType("");
              setDocumentsToUpload([]);
              setDocumentName("");
              clearFilters();
            })
          );
          setUploading(false);
        } catch (error) {
          console.log(error);
          setUploading(false);
          message.error(`${file.name} upload failed.`);
        }
      })
      .catch((error) => {});
  };

  const customRequest = async (option) => {
    const { file, onSuccess } = option;

    setFile(file);
    setContentType(file.type);
    setDocumentName(file.name);
    const inputVariables = {
      filePath: `${memberId ? memberIdNumber : companyId}/${file.name}`,
      contentType: file.type,
      forCompany: memberId ? false : true,
      companyName: companyName ? companyName : "",
    };
    if (memberId) {
      inputVariables["userId"] = memberId;
    }
    getPresignedUrl({
      variables: {
        input: inputVariables,
      },
    });

    setTimeout(() => {
      onSuccess();
    }, 3500);
  };

  return (
    <Modal
      open={visible}
      title="Create new document"
      okText="Create"
      onCancel={onCancel}
      onOk={uploadDocuments}
      okButtonProps={{
        className: uploading ? "purple-button no-interact" : "purple-button",
      }}
      cancelButtonProps={{
        className: uploading ? "no-interact" : null,
      }}
      okType="default"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Document Type"
          name="documentTypeId"
          rules={[
            {
              required: true,
              message: "Please select a document type!",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder="Select a document type"
          >
            {documentTypeOptions}
          </Select>
        </Form.Item>
        {form.getFieldValue("documentTypeId") === 24 && (
          <Form.Item
            label="Audit Year"
            name={"auditYear"}
            rules={[
              {
                required: false,
                message: "Please enter the year!",
              },
            ]}
          >
            <Select style={{ width: "100%" }} placeholder="Enter the year">
              {generateArrayOfYears().map((y) => (
                <Select.Option key={y} value={y.toString()}>
                  {y.toString()}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {ADMIN_STREAMS.includes(userRole) && (
          <Form.Item
            label="Company"
            name="companyId"
            rules={[
              {
                required: true,
                message: "Please select the company!",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select type company name"
              showSearch
              onSearch={handleCompaniesSearch}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              notFoundContent={null}
              loading={loadingCompanies}
            >
              {companyNameOptions}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="proofOfExemption"
          rules={[
            {
              required: true,
              message: "Please indicate",
            },
          ]}
        >
          <Dragger
            disabled={uploading}
            multiple={false}
            customRequest={customRequest}
          >
            <p
              className="ant-upload-drag-icon"
              style={{ marginBottom: 50, marginTop: 10 }}
            >
              <InboxOutlined />
            </p>
            <p className="ant-upload-text" style={{ marginBottom: 15 }}>
              Click or drag a file to this area to upload
            </p>
          </Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateDocumentForm;
